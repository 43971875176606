var centerIntMap=[15.462670, 0.693865];
//International map---------------------------------------------------------------------------------
var intmap = L.map('international_map', {zoomControl: false, scrollWheelZoom: false}).setView(centerIntMap, 3);
L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
maxZoom: 17,
minZoom: 3,
attribution: '<a href="https://www.mapbox.com/">Mapbox</a>',
id: 'mapbox/light-v9',
tileSize: 512,
zoomOffset: -1
}).addTo(intmap);


//Icons styles----------------------------------------------------------
var myIcon = L.divIcon({className: 'icon-pulse'});

var logo_core = '<div class="logo_core d-flex justify-content-center align-items-center text-primary"><span>core</span>solutions</div>';

var logo = '<div class="w-100 fwcc logo" style="height: 35%;background-image: url(../../../../../../assets/img/client_int/feda.png); background-color: transparent; background-position: center; background-repeat: no-repeat; background-size: contain;"></div>';

var name = '<div class="name fwcc">Forces Elèctriques d\'Andorra</div>';
var description = '<div class="description fwcc">Inventario de bienes, conciliación económica y valoración de inmuebles</div>';
var link = '<div class="link fwcc"><a>Ver noticia</a></div>';


$.ajax({
    url: "/mapworks",
    type: 'GET',
    
    data: {
        "_method": 'GET',
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        
    },
    success: function (data) {
        
        for(var i in data) {

            logo = '<div class="w-100 fwcc logo" style="height: 35%;background-image: url('+data[i].logo+'); background-color: transparent; background-position: center; background-repeat: no-repeat; background-size: contain;"></div>';

            name = '<div class="name fwcc">'+data[i].name+'</div>';
            
            description = '<div class="description fwcc">'+data[i].description+'</div>';
            
            link = '<div class="link fwcc"><a href="'+data[i].link+'">Ver noticia</a></div>';
            
            
            //Markers------------------------------------------------------------------------------------------------------------------------
            L.marker([data[i].latitude, data[i].longitude], {icon: myIcon}).bindPopup(logo_core+logo+name+description+link).addTo(intmap).on('click', clickZoom).getPopup().on('remove', closeBtnPopup);
            
        }
    }
});

//axiliar functions-------------------------------------------------------------------
function clickZoom(e) {//trasladar el mapa para que el popup salga centrado en el mapa
    intmap.setView([e.latlng.lat+4, e.latlng.lng],5);
}

function closeBtnPopup() {//al cerrar el popup centrar el mapa
    intmap.setView(centerIntMap, 3);
    intmap.closePopup();
}

//Controls international map--------------------------------------------------------------------------
//topleft------------------------------------------------------------------------------------------
L.control.custom({
    position: 'topleft',
    content: '<div class="style_fwsc" style="width: 100%;">' +
    
    //compress ---------------------------------------------------
    '<div class="style_fwss" style="padding-right: 5px;">'+
    '<div class="d-flex" style="border: .5px solid rgba(255,82,82, .85); border-radius: .9rem; width: 1.8rem!important; height: 1.8rem!important; flex-wrap: wrap; justify-content: center; align-content: center; background-color: rgba(255,255,255, .8);"><i id="zoomCenter" class="fa fa-compress-arrows-alt fa-lg" style="color: #ff5252;"></i></div>' +
    '</div>' +
    //compress ---------------------------------------------------
    
    '</div>',//Principal
    classes: 'style_fwcc',
    id: 'topleft_map',
    style: {
        margin: '10px',
        cursor: 'pointer',
    },
    datas: {
        'foo': 'bar'
    },
    events: {
        click: function(data) {
            
            switch (data.target.id) {
                
                case 'zoomCenter':
                intmap.setView(centerIntMap, 3);
                intmap.closePopup();//cerrar todos los popup si alguno se encuentra abierto
                //$('#topRightMap').html('<span class="text-primary"><b>core</b>solutions España</span>');
                break;
                
                default:
                break;
            }
        }
    }
}).addTo(intmap);

//topright-----------------------------------------------------------------------------------------------
L.control.custom({
    position: 'topright',
    content: '<div id="topRightMapInt"><h2 class="content-title intmap-title">Nuestros clientes internacionales</h2></div>',
    id: 'topright_map',
    style: {
        width: '100vw',
        margin: '3.75rem 0px 0px 0px',
        padding: '0 0 0 0',
        cursor: 'pointer',
        
    }
}).addTo(intmap);
